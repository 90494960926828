<template>
  <LandingPage>
    <h1 class="display-1 ma-4">
      <slot name="errorMessage"></slot>
    </h1>
    <slot name="additionalItems">
      <div>
        <v-btn class="ma-4" large color="primary" @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left</v-icon> Go back to previous page
        </v-btn>
      </div>
      <div>
        <v-btn class="ma-4" large color="success" @click="$router.push('/')">
          <v-icon left>mdi-home</v-icon> Return to homepage
        </v-btn>
      </div>
    </slot>
  </LandingPage>
</template>

<script>

import LandingPage from '@/components/Core/LandingPage/LandingPage'

export default {
  components: {
    LandingPage
  },
  name: 'Error'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
