<template>
  <ErrorBase>
    <template v-slot:errorMessage>{{ code }} - {{ message }}</template>
  </ErrorBase>
</template>

<script>

import ErrorBase from './ErrorBase'

export default {
  extends: ErrorBase,
  components: {
    ErrorBase
  },
  props: {
    message: {
      type: String,
      default: 'Internal server error.'
    },
    code: {
      type: Number,
      default: 500
    }
  },
  name: 'ErrorWithCustomMessage'
}
</script>
