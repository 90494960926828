<template>
  <div class="layout-main-wrapper">
    <v-main class="layout-main">
      <slot></slot>
    </v-main>
  </div>
</template>

<script>

import LoggedIn from '@/components/Layout/LoggedIn'
import LayoutMain from '@/components/Layout/Main'

export default {
  components: {
    LayoutMain
  },
  extends: LoggedIn,
  name: 'LoggedInFullscreen',
  props: {
    showBackButton: {
      type: Boolean,
      default: false
    },
    hideScrollBar: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    hideScrollBar: function (hideScrollBar) {
      LayoutMain.methods.handleHideScrollBar(hideScrollBar)
    }
  },
  mounted () {
    this.hideScrollBar = this.hideScrollBar;
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.logged-in-empty {
  .container.page {
    padding-top: 0px;
  }
}
</style>
